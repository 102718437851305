<script>
import ResultCardMobileVue from "./ResultCardMobile.vue";
import TableFilters from "./TableFilters.vue";
import ResultModal from "./ResultModal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ResultCardMobileVue,
    TableFilters,
    ResultModal,
  },
  data() {
    return {
      users: [],
      fields: [],
      modalVisible: false,
      itemByModal: {},
      filters: {
        city: false,
        office: false,
        points: false,
        votes: false,
      },
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters("competitions", ["getResults", "getCompetition"]),
    screen() {
      return this.$screen.width;
    },
    applyClass() {
      return this.screen > 768 ? true : false;
    },
  },
  methods: {
    ...mapActions("competitions", ["getResultsDb"]),
    openModal(data, idx) {
      data.idx = idx;
      this.itemByModal = data;
      this.modalVisible = true;
    },

    closeModal() {
      this.itemByModal = {};
      this.modalVisible = false;
    },

    sortData(field) {
      if (this.fields.find((f) => f.key === field && f.isSort)) {
        this.users.sort((a, b) => {
          const valueA = a.user[field];
          const valueB = b.user[field];

          if (typeof valueA === "string" && typeof valueB === "string") {
            return valueA.localeCompare(valueB);
          } else {
            return valueA - valueB;
          }
        });
      }
    },
    filterUsers() {
      const lowercaseSearchTerm = this.searchTerm.toLowerCase();
      this.users = this.getResults
        .map((result) => {
          return {
            id: result.id,
            user: {
              _id: result.user._id,
              picture: result.user.picture,
              user: result.user.user,
              artisticName: result.user.artisticName,
              city: result.user.city,
              office: result.user.office,
              points: result.totalPoints,
              votes: result.points.length,
              votesPeople: result.points,
            },
          };
        })
        .filter((user) => {
          const userFullName = user.user.artisticName || user.user.user;
          return userFullName.toLowerCase().includes(lowercaseSearchTerm);
        });
    },
  },
  async mounted() {
    await this.getResultsDb({ contestId: this.$route.params.id, query: `view=managment&page=0` });

    this.users = this.getResults.map((result) => {
      return {
        id: result.id,
        user: {
          _id: result.user._id,
          picture: result.user.picture,
          user: result.user.user,
          artisticName: result.user.artisticName,
          city: result.user.city,
          office: result.user.office,
          points: result.totalPoints,
          votes: result.points.length,
          votesPeople: result.points,
        },
        style: {
          color: "#bd0909",
        },
      };
    });
    this.fields = [
      {
        id: 1,
        name: "nombre",
        isSort: false,
        key: "name",
      },
      {
        id: 2,
        name: "ciudad",
        isSort: true,
        key: "city",
      },
      {
        id: 3,
        name: "sede",
        isSort: true,
        key: "office",
      },
      {
        id: 4,
        name: "posicion",
        isSort: true,
        key: "points",
      },
    ];
  },
};
</script>

<template>
  <section class="competition__result">
    <p :class="{ competition__participants: true, competition__participantsDesk: applyClass }">Participantes</p>
    <label :class="{ competition__searchResult: true, competition__searchResultDesk: applyClass }">
      <iconic name="my_search" class="competition__searchIcon" />
      <input type="text" v-model="searchTerm" @input="filterUsers" placeholder="Buscar modelo" class="competition__searchInput" />
    </label>
    <template v-if="screen <= 768">
      <ResultCardMobileVue
        v-for="user in users"
        :key="user.user._id"
        :user="user"
        :points="user.points"
        class="competition__cardResultMobile"
        @openModal="openModal"
      />
    </template>
    <TableFilters v-else :headers="fields" :data="users" :competition="getCompetition" :modal="true" @openModal="openModal" @sortData="sortData" />

    <ResultModal :visible="modalVisible" :item="itemByModal" @closeModal="closeModal" />
  </section>
</template>

<style lang="scss">
.competition {
  &__result {
    position: relative;
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    max-width: 981px;
    margin-top: 1rem;
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(92, 89, 89, 0.25);
  }
  &__participants,
  &__participantsDesk {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  &__participantsDesk {
    margin-bottom: 2.5rem;
  }
  &__searchResult,
  &__searchResultDesk {
    @include Flex();
    padding: 10px 16px;
    gap: 5px;
    max-width: 300px;
    margin-bottom: 1rem;
    border-radius: 22px;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 0px 4px 0px #5c595940;
  }
  &__searchResultDesk {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  &__searchIcon {
    color: #000;
  }
  &__searchInput {
    width: 100%;
    border: none;
    background: transparent;
  }

  &__cardResultMobile {
    @include Flex(row, space-between);
    width: 100%;
    padding: 15px;
    margin: 7px 0px;
    border-radius: 8px;
    background: rgba(245, 245, 245, 1);
    box-shadow: 0 0 4px rgba(55, 55, 55, 0.25);
  }
}
</style>
