<script>
import ToggleSwitch from "../widgets/ToggleSwitch.vue";
import ModalModelCompetition from "@/components/Competitions/ModalModelCompetition.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      rooms: [],
      page: 1,
      cpage: 1,
      isLoading: false,
      totalRooms: null,
      statusFlag: false,
      isMantenience: null,
      head: ["NOMBRE", "Ciudad", "Sede", "PARTICIPA"],
      modelsSelectList: [],
      currentParticipants: [],
      showModal: false,
      selectedModel: null,
      currentToggle: null,
    };
  },
  components: { ToggleSwitch, ModalModelCompetition },
  props: ["dataModels", "statusRegister"],
  async mounted() {
    this.initData();
  },
  methods: {
    ...mapActions("competitions", ["getCompetitionParticipant", "registerCompetitionParticipant", "removeCompetitionParticipant"]),
    async initData() {
      const { participants } = await this.getCompetitionParticipant({ contestId: this.$route.params.id });

      this.currentParticipants = participants;
      if (participants) {
        participants.forEach((element) => {
          this.modelsSelectList.push(element.user._id);
        });
      }
    },
    clearData() {
      this.modelsSelectList = [];
    },
    classHead(item) {
      switch (item) {
        case "":
          return "rooms__item--space";
        case "Comentarios":
          return "rooms__item--comment";
        default:
          return "";
      }
    },
    validateIncludeParticipants(userCheck) {
      if (!this.modelsSelectList.includes(userCheck._id)) {
        this.modelsSelectList.push(userCheck._id);
      }
    },
    check(event, userCheck) {
      const isChecked = event.srcElement.checked ? true : false;
      if (isChecked) {
        this.validateIncludeParticipants(userCheck);
        this.registerCompetitionParticipant({ newUser: { user: userCheck._id }, contestId: this.$route.params.id });
      } else {
        let index = this.modelsSelectList.indexOf(userCheck._id);
        if (index !== -1) {
          this.modelsSelectList.splice(index, 1);
          this.removeCompetitionParticipant({ userId: userCheck._id, contestId: this.$route.params.id });
        }
      }
    },
    validateMod(id) {
      return this.modelsSelectList.includes(id) ? { label: "Si", value: true } : { label: "No", value: false };
    },
    async showModalDetails(result) {
      this.showModal = !this.showModal;
      this.selectedModel = result;
    },
    async cancelModalDetails() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<template>
  <div class="rooms" v-if="!statusRegister">
    <div class="rooms__head">
      <div :class="['rooms__item', classHead(item)]" v-for="(item, idx) in head" :key="idx">
        {{ item }}
      </div>
    </div>
    <div class="rooms__container">
      <div v-for="(user, idx) in dataModels" :key="idx">
        <div class="rooms__card">
          <div class="rooms__info" @click="showModalDetails(user)">
            <div class="rooms__li rooms__li--name">
              <span class="mobile">Nombre:</span>
              <div class="rooms__li_avatarC">
                <div class="rooms__li_avatarC--content">
                  <avatar class="rooms__li_avatarC__avatar" :user="user"></avatar>
                </div>
                <p class="__name">
                  {{ user.artisticName }}
                </p>
              </div>
            </div>
            <div class="rooms__li">
              <span class="mobile">Ciudad:</span>
              <span class="rooms__li rooms__li--city">{{ user.city }}</span>
            </div>
            <div class="rooms__li">
              <span class="mobile">Sede:</span>
              <span class="rooms__li rooms__li--sede">{{ user.office }}</span>
            </div>
            <div class="rooms__li rooms__li--toggle">
              <span class="mobile">Participa:</span>
              <div class="rooms__li--toggle--label">
                <span class="answer">
                  {{ validateMod(user._id).label }}
                </span>
                <ToggleSwitch :checked="validateMod(user._id).value" @change="check($event, user)" ref="toggle" class="switch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spinner" v-if="isLoading"></div>
    <ModalModelCompetition
      v-if="showModal"
      @close="showModalDetails()"
      @calncel="cancelModalDetails()"
      :participant="selectedModel"
      :participantsList="currentParticipants"
    />
  </div>
  <div v-else>
    <div class="rooms__notRegister">
      <span class="rooms__notRegister_label">Ingrese el nombre del concurso para iniciar.</span>
    </div>
  </div>
</template>

<style lang="scss">
.rooms {
  border-radius: 12px;
  background: #fff;
  margin-top: 25px;
  &__notRegister {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    color: $primary;
    font-weight: 800;
  }

  .activeBtn {
    display: none;
  }

  &__card {
    margin: 0 auto;
    max-width: $desktop_width;
    background-color: $white;
    box-shadow: 0 2px 5px $alto;
    padding: 0;
    font-size: 14px;
    height: 200px;
    border-bottom: 1px solid #e5e5e5;
  }

  &__head {
    display: none;
  }

  &__info {
    display: block;
    padding: $mpadding;
    cursor: pointer;

    &:hover {
      color: $primary;
      transition: 0.4s;

      &--name {
        color: $primary;
        transition: 0.4s;
      }
    }
  }

  .mobile {
    font-weight: bold;
  }

  &__li {
    display: flex;
    margin: 1px;
    justify-content: space-between;

    &--room {
      color: $lipstick;
      font-weight: bold;
    }

    &--toggle {
      margin-bottom: 10px;
      align-items: center;

      &--label {
        display: flex;

        span {
          margin-right: 5px;
          width: 20px;
        }
      }
    }

    &_avatarC {
      display: flex;
      align-items: center;
      width: 100%;

      &--content {
        width: 30%;
      }

      &__avatar {
        width: 35px;
        height: 35px;
        border: 2px solid $primary-color;
      }

      .__name {
        padding-left: 5px;
        width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__btn {
    display: flex;
  }

  &__saved {
    margin: 0 auto;
    border: none;
    padding: 3px $mpadding/2;
    background-color: $lipstick;
    color: $white;
    border-radius: 5px;

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.97);
    }

    &:hover {
      opacity: $opacity-button;
    }
  }

  .textarea {
    min-height: 52px;
    max-height: 52px;
    max-width: 241px;
    margin-bottom: 10px;
    overflow-y: auto;
    border: 1px solid $alto;
    border-radius: 5px;
    font-size: 13px;
    padding: 7px 5px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
    }

    &:focus {
      outline: none;
    }
  }
}

@media (min-width: $tablet_width) {
  .rooms {
    border: 1px solid #dadada;
    .mobile {
      display: none;
    }

    &__card {
      font-size: 16px;
      height: 55px;
    }

    &__head {
      display: flex;
      margin: 0 auto;
      max-width: $desktop_width;
      border: 5px 5px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background: rgba(109, 111, 128, 0.08);
      border-bottom: 1px solid #e5e5e5;
      padding: $mpadding/2 $mpadding * 2;
      font-weight: bold;
      height: 50px;
      align-items: center;
    }

    &__item {
      width: 24%;

      text-transform: uppercase;
      font-weight: 800;

      &--space {
        width: 10%;
      }

      &--comment {
        width: 30%;
      }
    }

    &__info {
      display: flex;
      justify-content: space-around;
      padding: 0 $mpadding * 2;
      height: 100%;
    }

    &__comment {
      width: 30%;
    }

    &__li {
      display: flex;
      align-items: center;
      width: 25%;
      margin: 0 1px;

      &--toggle {
        width: 30%;
        display: flex;
        margin: 0 auto;
      }
    }

    &__btn {
      display: inline;
      width: 10%;
    }

    &__saved {
      margin-left: 30px;
    }

    .textarea {
      min-height: 32px;
      max-height: 32px;
      margin-bottom: 0;
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .rooms__card {
    position: relative;
    width: 100%;
    padding: 15px;
    border-radius: 12px;
    background: #f5f5f5;
    box-shadow: 0px 0px 4px 0px rgba(55, 55, 55, 0.25);
    height: 75px;
    margin-bottom: 10px;
    .mobile {
      visibility: hidden;
    }
    .switch {
      position: absolute;
      top: 15px;
      right: 15px;
    }
    .answer {
      visibility: hidden;
    }
    .rooms__li_avatarC {
      position: absolute;
      top: 15px;
      left: 15px;
    }
    .__name {
      position: absolute;
      width: 160px;
      top: 0px;
      left: 40px;
      font-size: 16px;
      color: #000;
    }
    .rooms__li--sede {
      position: absolute;
      top: 40px;
      left: 125px;
    }
    .rooms__li--city {
      position: absolute;
      top: 40px;
      left: 60px;
    }
  }
}
</style>
