<script>
  import ToggleSwitch from "../widgets/ToggleSwitch.vue";
  import TableParticipants from "./TableParticipants.vue";
  import ResultsVue from "./Results/Results.vue";
  import Awards from "./Awards.vue";
  import PolicyUsersViewVue from "./PolicyCompetitions/PolicyUsersView.vue";
  import { mapActions, mapGetters, mapMutations } from "vuex";

  export default {
    name: "createCompetition",
    components: {
      ToggleSwitch,
      TableParticipants,
      Awards,
      ResultsVue,
      PolicyUsersViewVue,
    },
    data() {
      return {
        title: "",
        searchModels: "",
        askComponentsInfo: [0],
        sectionStatus: {
          settings: true,
          results: false,
        },
        section2Status: {
          awards: false,
          settings: true,
        },
        formAction: "post",
        isResgister: false,
        isChecked: true,
        allChecked: false,
        awrdsRangeChecked: false,
        loading: false,
        startDate: "",
        endDate: "",
        startDateTime: "",
        endDateTime: "",
        formData: {
          name: "",
          description: "",
          statusModelsAll: "",
          visualModelsCount: 0,
          dataModelsSelect: [],
          selectedHourInit: "",
          selectedHourEnd: "",
          showTop: 0,
          totalPodium: 3,
          typeScore: "",
          showInNews: false,
        },
        awardsList: [],
        hourTimeList: [],
        statusSelected: "",
        dicStatus: {
          pending: "Pendiente",
          active: "Activo",
          inactive: "Inactivo",
          finished: "Finalizado",
        },
        statusAvailable: {
          pending: ["pending", "active", "inactive"],
          active: ["active", "finished"],
          finished: ["active", "finished"],
          inactive: ["inactive"],
        },
        arrTypes: [
          { name: "Por votos", value: "vote", statusInternal: false, statusExternal: false, disabledInternal: true, disabledExternal: true },
          { name: "Por tips", value: "tip", statusInternal: false, statusExternal: false, disabledInternal: false, disabledExternal: true },
          { name: "Por posición", value: "position", statusInternal: false, statusExternal: false, disabledInternal: false, disabledExternal: true },
        ],
        usersDb: [],
      };
    },
    computed: {
      ...mapGetters("competitions", ["getCompetitions", "getCompetition", "getParticipants"]),
      ...mapGetters("user", ["getOrderUsersByArea"]),
      modelsToSelect() {
        const search = this.searchModels.toLowerCase();
        const models = this.getOrderUsersByArea.filter((ele) => ele.name === "modelos");
        return models[0]?.users.filter((model) => {
          const { user, city, artisticName, office } = model;
          const includesUser = this.verifyIncudeInDate(user, search);
          const incudesCity = this.verifyIncudeInDate(city, search);
          const incudesArtisticName = this.verifyIncudeInDate(artisticName, search);
          const incudesOffice = this.verifyIncudeInDate(office, search);

          return includesUser || incudesCity || incudesArtisticName || incudesOffice;
        });
      },
      showSearch() {
        return this.section2Status.settings && this.$screen.width > 550;
      },
      availableStatus() {
        return this.statusAvailable[this.getCompetition?.status];
      },
      startDateFormat() {
        return this.createDateString(this.startDate, this.startDateTime);
      },
      endDateFormat() {
        return this.createDateString(this.endDate, this.endDateTime);
      },
    },
    methods: {
      ...mapActions("competitions", ["createNewCompetition", "updateCompetition", "getCompetitionById", "activeAllParticipants"]),
      ...mapActions("user", ["orderUsersAreas"]),
      ...mapMutations("competitions", ["setUsersSelecetedView", "setUsersSelectedInteractive", "setUsersSelecetedReset"]),
      setDate(key) {
        const currentDate = new Date();
        const newvalueDate = new Date(this[`${key}Format`]);

        if (newvalueDate < currentDate) {
          currentDate.setHours(currentDate.getHours() + 1, 0, 0, 0);
          this.setFullDates(currentDate, key);
        }

        if (!this[`${key}Time`]) {
          const dateToTime = new Date(this[`${key}Format`]);
          const hours = dateToTime.getHours();
          this[`${key}Time`] = `${hours < 10 ? "0" : ""}${hours}:00`;
        }

        if (this.startDateFormat && this.endDateFormat && new Date(this.startDateFormat) >= new Date(this.endDateFormat)) {
          const dateFormat = new Date(this.startDateFormat);
          dateFormat.setHours(dateFormat.getHours() + 1);
          this.setFullDates(dateFormat, "endDate");
        }

        this.updateDates();
      },
      setFullDates(dateInit, key) {
        this[key] = dateInit.toISOString().split("T")[0];
        const hours = dateInit.getHours();
        this[`${key}Time`] = `${hours < 10 ? "0" : ""}${hours}:00`;
      },
      async updateDates() {
        if ((!this.endDateFormat && !this.startDateFormat) || this.loading) {
          return;
        }

        const body = {};

        if (this.endDateFormat) {
          body.endDate = this.endDateFormat;
        }
        if (this.startDateFormat) {
          body.startDate = this.startDateFormat;
        }

        await this.sendUpdateData(body);
      },
      createDateString(dateFull, dateTime) {
        if (!dateFull) {
          return "";
        }

        const date = `${dateFull}T${dateTime || "00:00:00"}`;
        const dateObj = new Date(date);

        return dateObj.toISOString();
      },
      initData(comp) {
        this.formData.typeScore = comp.typeScore;
        this.formData.status = comp.status;
        this.formData.name = comp.name;
        this.formData.description = comp.description;
        this.formData.showTop = comp.showTop;
        this.formData.showInNews = comp.showInNews;
        this.formData.totalPodium = comp.totalPodium;
        this.awrdsRangeChecked = comp?.positionByRange;
        if (comp?.startDate) {
          const da1 = new Date(comp.startDate);
          this.setFullDates(da1, "startDate");
        }
        if (comp?.endDate) {
          const da1 = new Date(comp.endDate);
          this.setFullDates(da1, "endDate");
        }
        if (comp?.showTop !== 0) {
          this.isChecked = false;
        }
        this.arrTypes.map((it) => {
          if (it.value === comp.typeScore) {
            if (comp.typeContest === "internal") {
              it.statusInternal = true;
            } else {
              it.statusExternal = true;
            }
          }
        });
        this.awardsList = comp.awards;
      },
      verifyIncudeInDate(dateIn, compare) {
        const formattedDate = this.cleanString(dateIn || "");
        const formattedCompare = this.cleanString(compare);
        return formattedDate.includes(formattedCompare);
      },
      cleanString(str) {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      },
      generateHoursDay() {
        const horas = [];
        for (let i = 0; i < 24; i++) {
          const hora24 = i;
          const hora12 = i % 12 === 0 ? 12 : i % 12;
          const ampm = i < 12 ? "am" : "pm";
          const horaLabel = `${hora12}:00 ${ampm}`;
          const hor = hora24 < 10 ? `0${hora24}:00` : `${hora24}:00`;
          horas.push({ label: horaLabel, value: hor });
        }
        return horas;
      },
      backViewCompetitions() {
        this.$router.push({ name: "competition" });
      },
      isStatusEdit() {
        return this.$route.params.id ? true : false;
      },
      sendCreateUpdate() {
        if (this.formData.showTop < 0) {
          this.formData.showTop = 0;
        }
        if (this.formData.name && !this.formData._id && !this.$route.params.id) {
          this.sendData();
        } else {
          this.updateData();
        }
      },
      updateHours_test() {
        this.updateData();
      },
      updateTime_test(val) {
        const va = new Date(this.formData[val] + "T00:00:00");
        const currentDate = new Date(va);

        if (va < currentDate) {
          currentDate.setHours(currentDate.getHours() + 1, 0, 0, 0);
          const dateparce = currentDate.toISOString().split("T");
          this.formData[val] = dateparce.split("T")[0];
        }
        this.formData[`${val}Time`] = "06:00";
        this.updateData();
      },
      changeSection(section) {
        for (const key in this.sectionStatus) {
          this.sectionStatus[key] = key === section;
        }
      },
      changeSection2(section) {
        for (const key in this.section2Status) {
          this.section2Status[key] = key === section;
        }
      },
      parseFieldsData(formUpdata) {
        let currentUpdateForm = {};
        if (this.getCompetition) {
          if (this.getCompetition.name !== formUpdata.name) {
            currentUpdateForm.name = formUpdata.name;
          }
          if (this.getCompetition.description !== formUpdata.description) {
            currentUpdateForm.description = formUpdata.description;
          }
          if (formUpdata.startDate && formUpdata.startDateTime) {
            let d1 = new Date(formUpdata.startDate + `T${formUpdata.startDateTime}:00`);
            currentUpdateForm.startDate = d1;
          }
          if (formUpdata.endDate && formUpdata.endDateTime) {
            let d1 = new Date(formUpdata.endDate + `T${formUpdata.endDateTime}:00`);
            currentUpdateForm.endDate = d1;
          }
          if (this.getCompetition.showTop !== formUpdata.showTop) {
            currentUpdateForm.showTop = formUpdata.showTop;
          }
          if (this.getCompetition.totalPodium !== formUpdata.totalPodium) {
            currentUpdateForm.totalPodium = formUpdata.totalPodium;
          }
        }
        return currentUpdateForm;
      },
      async sendData() {
        const formData = {
          name: this.formData.name,
        };

        let newCompetition = await this.$store.dispatch("competitions/createNewCompetition", formData);
        if (newCompetition?.contest?._id) {
          this.isResgister = true;
          this.formData._id = newCompetition.contest._id;
          this.$toast.success("Concurso creado");

          return this.$router.push(`/competition/editCompetition/${newCompetition.contest._id}`).catch(() => {});
        }
      },
      async updateData() {
        const formJson = this.parseFieldsData(this.formData);
        await this.sendUpdateData(formJson);
      },
      async sendUpdateData(body) {
        this.loading = true;
        const updateCompetition = await this.$store.dispatch("competitions/updateCompetition", { newCompetition: body, _id: this.$route.params.id });
        const { error } = updateCompetition;

        if (error) {
          this.$toast.error(error);
          return;
        } else {
          this.$toast.success("Actualizando");
          await this.getCompetitionById({ _id: this.$route.params.id });
          this.initData(this.getCompetition);
        }

        this.loading = false;
      },
      async updateStatus() {
        await this.sendUpdateData({ status: this.statusSelected });
      },
      async changeTypeUpdate(event, type, obj, idx) {
        event.preventDefault();

        this.arrTypes.map((it, index) => {
          it.statusExternal = false;
          it.statusInternal = false;
          if (type === "internal" && idx === index) {
            it.statusInternal = true;
          } else if (type === "external" && idx === index) {
            it.statusExternal = true;
          }
        });

        await this.sendUpdateData({ typeContest: type, typeScore: obj.value });
      },
      changeShowTop(event) {
        const isChecked = event.srcElement.checked ? true : false;
        this.isChecked = isChecked;
        if (isChecked) {
          this.formData.showTop = 0;
        }
        this.sendCreateUpdate();
      },
      async activeAllPartic(event) {
        const isChecked = event.srcElement.checked ? true : false;
        this.allChecked = isChecked;
        let action = isChecked ? "active" : "disable";
        const resp = await this.$store.dispatch("competitions/activeAllParticipants", { idContest: this.$route.params.id, actionParticipants: action });
        if (resp) {
          this.$toast.success("Actualizando");
          if (!isChecked) {
            this.$refs.participantsTable.clearData();
          } else {
            this.$refs.participantsTable.initData();
          }
        } else {
          this.$toast.error("Error");
        }
      },
      async activeRange(event) {
        const isChecked = event.srcElement.checked ? true : false;
        this.awrdsRangeChecked = isChecked;
        await this.sendUpdateData({ positionByRange: isChecked });
      },
      async updShowInNews(event) {
        const isChecked = !!event.srcElement?.checked;
        this.formData.showInNews = isChecked;
        await this.sendUpdateData({ showInNews: isChecked });
      },
      options() {
        this.usersDb = this.getOrderUsersByArea.map((area) => area.users).flat(Infinity);
      },

      initialDataBypolicies() {
        const dataView = this.getCompetition.policiesView?.users ? this.getCompetition.policiesView.users : [];
        const dataInteraction = this.getCompetition.policiesInteration?.users ? this.getCompetition.policiesInteration.users : [];

        const applyData = (data, setterFunction) => {
          data
            .map((user) => this.usersDb.find((ele) => ele._id === user))
            .filter((userFind) => userFind)
            .forEach((userFind) => setterFunction(userFind));
        };

        applyData(dataView, this.setUsersSelecetedView.bind(this));
        applyData(dataInteraction, this.setUsersSelectedInteractive.bind(this));
      },
    },
    async mounted() {
      await this.setUsersSelecetedReset();
      await this.orderUsersAreas();

      this.options();
      this.initialDataBypolicies();

      this.isResgister = this.isStatusEdit();
      if (this.isResgister) {
        await this.getCompetitionById({ _id: this.$route.params.id });
        this.initData(this.getCompetition);
      }
      this.hourTimeList = this.generateHoursDay();
      this.statusSelected = this.getCompetition.status;
    },
  };
</script>

<template>
  <div class="create">
    <div class="create__header">
      <h2 class="create__header--title create__headerName">Concursos</h2>
      <a class="create__header--return" @click="backViewCompetitions">
        <iconic name="close"></iconic>
      </a>
    </div>
    <div class="create__option">
      <p :class="sectionStatus.settings ? 'select' : 'create__option--item-active'" @click="changeSection('settings')">
        Configuración
      </p>
      <p :class="sectionStatus.points ? 'select' : 'create__option--item-active'" @click="changeSection('results')">
        Resultados
      </p>
    </div>
    <form v-if="sectionStatus.settings" class="formCompetition" id="competition">
      <div v-show="sectionStatus.settings">
        <div class="create__name">
          <div class="create__name--container">
            <label for="title" class="create__name--label">Titulo</label>
            <input class="create__name--input" id="nombre" type="text" placeholder="Nombre del concurso" @focusout="sendCreateUpdate" name="title" v-model="formData.name" required />
          </div>
          <div class="create__name--container">
            <label for="description" class="create__name--label">Subtitulo</label>
            <input class="create__name--input" id="description" name="description" type="text" placeholder="Subtitulo" @focusout="sendCreateUpdate" v-model="formData.description" required />
          </div>
        </div>
        <div class="create__programmer">
          <div class="create__programmerHeader">
            <p class="create__programmer--title">Cronograma de concurso</p>
            <select class="create__filterUser" v-model="statusSelected" @change="updateStatus">
              <option :value="status" v-for="(status, idx) in availableStatus" :key="idx">{{ dicStatus[status] }}</option>
            </select>
          </div>
          <div class="create__programmer--timer">
            <div class="create__programmer--seccion">
              <div class="create__programmer--calendar">
                <iconic name="calendar"></iconic>
                <p>Fecha inicial</p>
              </div>
              <div>
                <input @change="setDate('startDate')" :disabled="!isResgister" v-model="startDate" type="date" name="dateInit" class="create__programmer--date" />
              </div>
            </div>
            <div class="create__programmer--seccion">
              <div class="create__programmer--clock">
                <iconic name="clock_regular"></iconic>
                <p>Hora inicio</p>
              </div>
              <div>
                <select @change="setDate('startDate')" v-model="startDateTime" :disabled="!startDate" class="create__programmer--time">
                  <option value="">Seleccionar hora</option>
                  <option v-for="(hour, index) in hourTimeList" :value="hour.value" :key="index">{{ hour.label }}</option>
                </select>
              </div>
            </div>
            <div class="create__programmer--seccion">
              <div class="create__programmer--calendar">
                <iconic name="calendar"></iconic>
                <p>Fecha final</p>
              </div>
              <div>
                <input @change="setDate('endDate')" :disabled="!isResgister" v-model="endDate" type="date" name="endDate" class="create__programmer--date" />
              </div>
            </div>
            <div class="create__programmer--seccion">
              <div class="create__programmer--clock">
                <iconic name="clock_regular"></iconic>
                <p>Hora final</p>
              </div>
              <div>
                <select @change="setDate('endDate')" v-model="endDateTime" :disabled="!endDate" class="create__programmer--time">
                  <option value="">Seleccionar hora</option>
                  <option v-for="(hour, index) in hourTimeList" :value="hour.value" :key="index">{{ hour.label }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="create__programmer">
          <p class="create__programmer--title">Visualizacion de participantes</p>
          <p class="create__programmer--subtitle">Cantidad de participantes a visualizar</p>
          <div class="create__programmer--timer">
            <div class="create__programmer--seccion--positions">
              <div class="create__programmer--seccion--positions--label">
                <p class="create__programmer--text">Todos los participantes</p>
              </div>
              <div class="create__programmer--seccion--positions--button">
                <div class="create__toggle__section">
                  <ToggleSwitch :checked="isChecked" @change="($event) => changeShowTop($event)" :disabled="!isResgister" ref="toggle" class="toggle" />
                  <div class="create__viewListParticipant">
                    <p>Primeras</p>
                    <input
                      :disabled="isChecked"
                      class="create__name--inputPosition"
                      id="number"
                      type="number"
                      placeholder="0"
                      @change="sendCreateUpdate"
                      name="positions"
                      v-model="formData.showTop"
                      required
                    />
                    <p>Posiciones</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="create__programmer--line" />
          <div class="create__programmer--podium">
            <div>
              <p class="create__programmer--title">Visualizacion podio</p>
              <p class="create__programmer--subtitle">Cantidad de posiciones en el slider principal</p>
            </div>
            <div class="create__programmer--selectAmount">
              <div class="create__programmer--option">
                <input class="mycheck" type="radio" id="option1" value="3" @change="sendCreateUpdate" v-model="formData.totalPodium" required />
                <label for="option1">3</label>
              </div>
              <div class="create__programmer--option">
                <input class="mycheck" type="radio" id="option3" value="5" @change="sendCreateUpdate" v-model="formData.totalPodium" required />
                <label for="option3">5</label>
              </div>
            </div>
          </div>
          <div class="create__programmer--line" />
          <div class="create__programmer--podium">
            <div>
              <p class="create__programmer--title">Visualizacion en Noticias</p>
              <p class="create__programmer--subtitle">Se verá el podio en la vista de noticias</p>
            </div>
            <ToggleSwitch :checked="formData.showInNews" @change="($event) => updShowInNews($event)" :disabled="!isResgister" ref="toggle" class="toggle" />
          </div>
        </div>
        <div class="create__programmer">
          <div class="create__programmer__type create__programmer__type--headers">
            <div class="create__programmer__type--title">Tipo de puntuación</div>
            <div class="create__programmer__type--internal type--border">Interno</div>
            <div class="create__programmer__type--external">Externo</div>
          </div>
          <div class="create__programmer__type create__programmer__type--body" v-for="(type, index) in arrTypes" :key="index">
            <div class="create__programmer__type--title">{{ type.name }}</div>
            <div class="create__programmer__type--internal">
              <ToggleSwitch
                :disabled="type.disabledInternal || !isResgister"
                :checked="arrTypes[index].statusInternal"
                @change="($event) => changeTypeUpdate($event, 'internal', type, index)"
                ref="toggle"
              />
            </div>
            <div class="create__programmer__type--external">
              <ToggleSwitch
                :disabled="type.disabledExternal || !isResgister"
                :checked="arrTypes[index].statusExternal"
                @change="($event) => changeTypeUpdate($event, 'external', type, index)"
                ref="toggle"
              />
            </div>
          </div>
        </div>
        <div class="create__policiesCompetitions">
          <p class="create__policiesCompetitionsTitle">Politicas</p>
          <PolicyUsersViewVue :users="usersDb" />
        </div>

        <div class="create__programmer">
          <div class="create__optionWrapper">
            <div class="create__option">
              <p :class="section2Status.settings ? 'select' : 'create__option--item-active'" @click="changeSection2('settings')">Participantes</p>
              <p :class="section2Status.awards ? 'select' : 'create__option--item-active'" @click="changeSection2('awards')">Premios</p>
            </div>
            <div v-show="section2Status.settings" class="create__programmer__allParticipants">
              <div class="create__programmer__allParticipants--toggle">
                <span>Activar todos</span>
                <ToggleSwitch class="toggleSwitch" :checked="allChecked" @change="($event) => activeAllPartic($event)" :disabled="!isResgister" ref="toggle" />
                <div class="create__wrapper" v-if="showSearch">
                  <input class="create__filterModel" type="text" placeholder="Buscar modelo" v-model="searchModels" />
                  <iconic name="icon_search"></iconic>
                </div>
              </div>
            </div>
            <div v-show="section2Status.awards" class="create__programmer__allParticipants">
              <div class="create__programmer__allParticipants--toggle">
                <span>Definir rango</span>
                <ToggleSwitch class="toggle" :checked="awrdsRangeChecked" @change="($event) => activeRange($event)" :disabled="!isResgister" ref="toggle" />
              </div>
            </div>
          </div>
          <div v-show="section2Status.settings">
            <TableParticipants ref="participantsTable" @select="sendCreateUpdate()" :dataModels="modelsToSelect" :statusRegister="!isResgister"></TableParticipants>
          </div>
          <div v-show="section2Status.awards">
            <Awards :awardsData="awardsList" :statusRegister="!isResgister" :statusRange="awrdsRangeChecked" :dataModels="modelsToSelect"></Awards>
          </div>
        </div>
      </div>
    </form>
    <ResultsVue v-else />
  </div>
</template>

<style lang="scss">
  .create {
    position: relative;
    min-height: 100vh;
    max-width: 1440px;
    width: 100%;
    padding: 50px 10px;

    &__toggle__section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
    }
    &__viewListParticipant {
      @include Flex(row, space-between);
      padding-left: 25px;
      border-left: 1px solid #d8d8d8;
    }

    &__header {
      @include Flex(row, space-between);
      width: 100%;

      &--return {
        cursor: pointer;
        font-weight: bold;
        color: #5b5957;
      }
    }

    &__headerName {
      font-size: 25px;
    }
    &__optionWrapper {
      @include Flex(row, space-between);
      flex-wrap: wrap;
      width: 100%;
      border-bottom: 1px solid #d8d8d8;
    }
    &__filterUser {
      height: 29px;
      padding: 0 5px;
      border: 1px solid $alto;
      border-radius: 6px;
    }
    &__wrapper {
      position: relative;
      display: inline-block;
      .iconic {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
      }
    }
    &__filterModel {
      height: 35px;
      width: 277px;
      padding: 10px 16px 10px 30px;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 22px;
    }
    &__option {
      @include Flex();
      .select {
        padding: 10px;
        color: $primary-color;
        font-weight: 600;
        font-size: 14px;
        border-bottom: 2px solid $primary-color;
      }

      &--item-active {
        padding: 10px 0;
        margin-right: 10px;
        font-size: 14px;

        &:hover {
          cursor: pointer;
          color: $primary-color;
          font-weight: 600;
        }
      }

      &--item-inactive {
        padding: 10px 0;
        margin-right: 10px;
        font-size: 14px;
        color: #6c757d;

        &:hover {
          cursor: no-drop;
          font-weight: 600;
        }
      }
    }

    &__name {
      width: 100%;
      max-width: 1440px;
      height: 172px;
      margin-top: 1rem;
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 20px 20px 20px;
      box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.25);

      &--container {
        height: 65px;
        margin: 2px 0;
      }

      &--label {
        font-size: 16px;
        text-align: left;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 0em;
        font-family: $first_font;
      }

      &--input {
        width: 100%;
        height: 39px;
        border-radius: 4px;
        padding: 5px;
        border: 0.5px solid #a8a9a9;
        cursor: text;

        &::placeholder {
          padding: 0;
        }
      }

      &--inputPosition {
        width: 50px;
        height: 39px;
        border-radius: 4px;
        padding: 5px;
        border: none;
        border-bottom: 0.5px solid #a8a9a9;
        background-color: #ececec;
        text-align: center;
      }
    }

    &__btn {
      position: relative;
      text-align: center;
      top: -290px;
      left: 78%;

      .btnCreate {
        width: 30px;
        height: 30px;
        border: none;
        cursor: pointer;
        color: #fff;
        border-radius: 50%;
        background-color: $primary-color;

        .tooltiptext {
          top: 0px;
          left: 40px;
          opacity: 0.8;
          width: 150px;
          position: absolute;
          border-radius: 6px;
          visibility: hidden;
          padding: 3px 0 3px 5px;
          background-color: #000;

          &::after {
            content: "";
            top: 8px;
            left: -4px;
            border-width: 7px;
            margin-left: -10px;
            position: absolute;
            border-style: solid;
            border-color: transparent #000 transparent transparent;
          }
        }

        :nth-child(1) {
          margin-top: 3.5px;
        }

        &:hover {
          transform: scale(1.05);
          background: $primary_color;
          transition: all 0.3s ease-in-out;

          .tooltiptext {
            visibility: visible;
          }
        }
      }
    }

    .data-error {
      background: $primary_color;

      &::placeholder {
        color: #fff;
      }
    }

    &__btns {
      width: 100%;
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: center;
      gap: 15px;
      height: 70px;
      margin-left: -110px;
      padding: 20px 0;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
      background: #fff;

      &--cancel {
        border: 1px solid $primary_color;
        height: 35px;
        padding: 5px 10px;
        border-radius: 4px;
        color: #bd0909;
        cursor: pointer;
      }

      &--draft {
        border: 1px solid #bd090940;
        height: 35px;
        padding: 5px 10px;
        border-radius: 4px;
        background: #bd090940;
        color: #bd0909;
        cursor: pointer;
      }

      &--save {
        border: 1px solid #bd0909;
        height: 35px;
        padding: 5px 10px;
        border-radius: 4px;
        background: #bd0909;
        color: #fff;
        cursor: pointer;
      }
    }

    @media (min-width: 424px) {
      &__option {
        justify-content: flex-start;
      }
    }
    &__programmerHeader {
      @include Flex(row, space-between);
    }
    &__programmer {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      margin: 1rem 0;
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 20px 20px 20px;
      box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.25);
      &__allParticipants {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        &--toggle {
          display: flex;
          align-items: center;
          span {
            margin-right: 10px;
          }
          .toggleSwitch {
            margin-right: 10px;
          }
        }
      }
      &--seccion {
        width: 100%;

        @media (min-width: 1480px) {
          width: 25%;
        }
      }
      &--seccion--positions--button {
        margin-top: -15px;
        width: 70%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
      &--line {
        margin-top: 15px;
        margin-bottom: 10px;
        height: 1px;
        border-bottom: 1px solid #d8d8d8;
      }
      &--podium {
        display: flex;
        gap: 13%;
      }
      &--selectAmount {
        display: flex;
        justify-content: space-around;
        gap: 20px;
        position: relative;
        right: 20px;
        padding-top: 25px;
        .mycheck {
          width: 15px;
          height: 15px;
          padding: 9px;
          border-radius: 50%;
          border: solid 1.5px $chicago;
          &:checked {
            background-color: $primary-color;
            border-color: $primary-color;
          }
        }
      }
      &--option {
        display: flex;
        gap: 5px;
      }
      &--seccion--roles {
        width: 100%;
        display: flex;

        &__filtersApply {
          @include Flex(row, flex-start);
          margin-top: 0.5rem;
        }

        &__filtersApplyItem {
          display: flex;
          width: fit-content;
          margin-right: 1rem;
          padding: 0.2rem 0.6rem;
          color: $primary;
          font-weight: bold;
          border-radius: 1rem;
          background-color: rgba(242, 189, 189, 0.3);
        }

        &__filtersApplyIcon {
          font-size: 0.6rem;
          margin-left: 0.3rem;
          cursor: pointer;
        }
      }
      &--seccion--positions {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &--label {
          width: 30%;
        }
      }
      &--title {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
        line-height: 20px;
        letter-spacing: 0em;
        font-family: $primary-font;
      }
      &--subtitle {
        margin-top: 5px;
        font-style: italic;
      }
      &--timer {
        gap: 2%;
        display: block;
        margin-top: 15px;

        @media (min-width: 768px) {
          display: flex;
        }
      }
      &--activate {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        span {
          margin-right: 10px;
        }
      }
      &--calendar,
      &--clock {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
      }
      &--date {
        border: 1px solid #00000040;
        border-radius: 6px;
        height: 35px;
        width: 100%;
        padding: 0 10px;
      }
      &--time {
        border: 1px solid #00000040;
        border-radius: 6px;
        height: 35px;
        width: 100%;
        padding: 0 10px;
      }
      &__type {
        display: flex;

        &--headers {
          margin-bottom: 10px;
        }
        &--body {
          padding: 10px 0px;
          border-bottom: 1px solid #d8d8d8;
        }
        &--title {
          width: 60%;
        }
        &--internal {
          width: 20%;
          display: flex;
          justify-content: center;
        }
        &--internal.type--border {
          border-right: 1px solid #d8d8d8;
        }
        &--external {
          width: 20%;
          display: flex;
          justify-content: center;
        }
      }
    }
    &__policiesCompetitions {
      position: relative;
      padding: 1rem;
      overflow: hidden;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.25);
    }
    &__policiesCompetitionsTitle {
      margin-bottom: 0.5rem;
      font-weight: bold;
      color: $primary;
    }
    @media (max-width: 570px) {
      &__programmer__allParticipants {
        margin: 10px 0;
      }
      &__toggle__section {
        @include Flex(flex, space-between);
        flex-direction: column;
        .toggle {
          margin-top: 20px;
        }
      }
      &__viewListParticipant {
        position: relative;
        margin-top: 25px;
        right: 100%;
      }
    }
    @media (max-width: 380px) {
      &__viewListParticipant {
        position: relative;
        margin-top: 40px;
        right: 80%;
      }
    }
    @media (max-width: 330px) {
      &__viewListParticipant {
        position: relative;
        margin-top: 40px;
        right: 60%;
      }
    }
  }

  .Vue-Toastification {
    &__toast {
      background-color: #ffff;
      max-height: 59px;
      min-height: 6px;
      padding: 16px 24px;
      &-body {
        color: #000;
      }
      &--success {
        .Vue-Toastification__icon {
          color: #4caf50;
        }
        .Vue-Toastification__close-button {
          color: #000;
        }
      }
      &--error {
        .Vue-Toastification__icon {
          color: $primary;
        }
        .Vue-Toastification__close-button {
          color: #000;
        }
      }
      &--info {
        .Vue-Toastification__icon {
          color: #efefef;
        }
        .Vue-Toastification__info-button {
          color: #000;
        }
      }
    }
  }
</style>
