import { render, staticRenderFns } from "./ResultCardMobile.vue?vue&type=template&id=862d5b8e"
import script from "./ResultCardMobile.vue?vue&type=script&lang=js"
export * from "./ResultCardMobile.vue?vue&type=script&lang=js"
import style0 from "./ResultCardMobile.vue?vue&type=style&index=0&id=862d5b8e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports